import { Badge as ChBadge, BadgeProps, BadgeProps as ChBadgeProps } from '@chakra-ui/react'
import { transparentize } from 'polished'
import { Colors } from 'theme/constants'

interface Props extends ChBadgeProps {
  label: string
  isActive?: boolean
  linkTo?: string
}

const ActiveStyles: BadgeProps = {
  backgroundColor: 'brand.blue100',
  color: 'white',
}

export const Badge = ({ label, linkTo, isActive, ...rest }: Props) => (
  <ChBadge
    fontFamily="DM Mono"
    height="auto"
    py="8px"
    px="8px"
    borderRadius="sm"
    fontSize="12px"
    {...(linkTo && {
      _hover: {
        backgroundColor: transparentize(0.8, Colors.brand.blue100),
      },
    })}
    {...(isActive ? ActiveStyles : {})}
    {...rest}
  >
    {label}
  </ChBadge>
)
