import { ReactElement } from 'react'
import { AspectRatio, Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { IconCircleArrowRight } from '@tabler/icons-react'
import Image from 'next/image'
import Link from 'next/link'
import { Colors } from 'theme/constants'
import { Route } from 'constants/common/routes'

export const UserTypesSectionMobileCard = ({
  title,
  description,
  bulletPoints,
  imgSrc,
  bgColor,
}: {
  title: string
  description: string | ReactElement
  bulletPoints: string[]
  imgSrc: string
  bgColor: string
}) => (
  <Flex
    w="full"
    h="full"
    p={{ base: '32px', md: '48px' }}
    direction="column"
    borderRadius="24px"
    bg={bgColor}
    position="relative"
    overflow="hidden"
    shadow="primary"
  >
    <VStack w="full" h="full" spacing="16px" align="start">
      <Text fontSize="32px" fontFamily="DM Sans Semibold">
        {title}
      </Text>
      <Text fontSize="16px" maxW="320px" fontFamily="Montserrat">
        {description}
      </Text>
      <VStack w="full" align="start" spacing="4px">
        {bulletPoints.map((text) => (
          <HStack w="full" spacing="10px" align="start  " key={text}>
            <Box w="24px" h="24px">
              <IconCircleArrowRight stroke={1.5} color={Colors.brand.blue100} fill="#BDD4FF" />
            </Box>
            <Text fontSize="14px" fontFamily="DM Sans Medium" lineHeight="150%">
              {text}
            </Text>
          </HStack>
        ))}
      </VStack>
      <Link href={Route.SignUp()}>
        <Button variant="outline">Registrovať sa</Button>
      </Link>
    </VStack>
    <AspectRatio
      w="full"
      display={{ base: 'none', md: 'block' }}
      maxW="520px"
      ratio={4 / 3}
      position="relative"
    >
      <Box />
    </AspectRatio>
    <Box
      w="full"
      display={{ base: 'none', md: 'block' }}
      maxW="620px"
      position="absolute"
      right="-64px"
      bottom="-64px"
    >
      <AspectRatio w="full" maxW="620px" ratio={4 / 3} borderRadius="24px">
        <Image
          src={imgSrc}
          alt=""
          fill
          objectFit="cover"
          objectPosition="top"
          style={{ borderRadius: '12px', maxWidth: '680px' }}
        />
      </AspectRatio>
    </Box>
  </Flex>
)
