import { useState } from 'react'
import { Box, Container, Flex, Text, VStack } from '@chakra-ui/react'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import { Colors } from 'theme/constants'
import { UserTypesSectionCard } from './UserTypesSectionCard'
import { UserTypesSectionMobileCard } from './UserTypesSectionMobileCard'

const CustomerBulletPoints = [
  'Zadávanie a organizácia dopytov ',
  'Dopyty a ponuky na jednom mieste ',
  'Informácie o dodávateľoch',
  'Recenzie, hodnotenia, certifikáty...',
  'Odozvy na cenu, rozsah a termíny',
  'Prístup k priamym ponukám',
]

const ContractorBulletPoints = [
  'Na jednom mieste dopyty súkromných osôb a firiem',
  'Dopyty správcov budov a inštitúcií',
  'Dopyty servisnej a havarijnej služby',
  'Personalizovaná firemná prezentácia',
  'Optimalizácia, pomocné médiá,  telefonická asistencia...',
  'Odozvy na cenu, rozsah a termíny',
  'Systém priamej ponuky stavebných prác, súvisiacich služieb a produktov - umožňuje pridávanie akcií za najlepšie ceny!',
  'Ponuka zvyškového materiálu pre ďalšie použitie',
  'Ponuka odpadového materiálu, optimalizácia nákladov',
]

export const UserTypesSection = () => {
  const isOnMobile = useIsOnMobile()
  const [activeSlide, setActiveSlide] = useState(0)

  return (
    <Box w="full" bg="white">
      <Container maxW="container.xl" p={0} px={{ base: '16px', '2xl': 0 }} centerContent>
        <VStack w="full" spacing="68px">
          <VStack w="full" align={{ base: 'center', lg: 'start' }} spacing="16px">
            <Text
              as="h2"
              fontSize={{ base: '24px', lg: '32px' }}
              fontFamily="Montserrat Semibold"
              lineHeight="150%"
            >
              Jedno miesto{' '}
              <span
                style={{
                  fontFamily: 'Montserrat Semibold',
                  fontSize: 'inherit',
                  color: Colors.brand.blue100,
                  lineHeight: '150%',
                }}
              >
                pre všetkých
              </span>
              !
            </Text>
            <Text
              as="h3"
              fontSize={{ base: '14px', lg: '16px' }}
              maxW="520px"
              fontFamily="Montserrat"
              textAlign={{ base: 'center', lg: 'start' }}
            >
              Zadaj dopyt - nikdy nespí, je k dispozícii 24/7 pre dopytujúcich aj dodávateľov. Váš
              projekt začína u nás!
            </Text>
          </VStack>
          <Flex
            w="full"
            direction={{ base: 'column', lg: 'row' }}
            h={{ base: 'auto', lg: '600px' }}
            gap="24px"
          >
            {isOnMobile ? (
              <>
                <UserTypesSectionMobileCard
                  title="Pre zákazníkov"
                  description={
                    <>
                      Na každý dopyt <strong>obdržíte</strong> niekoľko{' '}
                      <strong>nezávislých ponúk</strong> od skúsených dodávateľov!
                    </>
                  }
                  bulletPoints={CustomerBulletPoints}
                  imgSrc="/img/offer-details.png"
                  bgColor="brand.blue20"
                />

                <UserTypesSectionMobileCard
                  title="Pre dodávateľov"
                  description={
                    <>
                      Spolupracujte s nami a <strong>získajte nové obchodné príležitosti.</strong>
                    </>
                  }
                  bulletPoints={ContractorBulletPoints}
                  imgSrc="/img/worker-sm.png"
                  bgColor="#B7F2CA"
                />
              </>
            ) : (
              <>
                <UserTypesSectionCard
                  title="Pre zákazníkov"
                  description={
                    <>
                      Na každý dopyt <strong>obdržíte</strong> niekoľko{' '}
                      <strong>nezávislých ponúk</strong> od skúsených dodávateľov!
                    </>
                  }
                  bulletPoints={CustomerBulletPoints}
                  imgSrc="/img/offer-details.png"
                  bgColor="brand.blue20"
                  isActive={activeSlide === 0}
                  onMouseEnter={() => {
                    setActiveSlide(0)
                  }}
                />

                <UserTypesSectionCard
                  title="Pre dodávateľov"
                  description={
                    <>
                      Spolupracujte s nami a <strong>získajte nové obchodné príležitosti.</strong>
                    </>
                  }
                  bulletPoints={ContractorBulletPoints}
                  imgSrc="/img/worker-sm.png"
                  isActive={activeSlide === 1}
                  bgColor="#B7F2CA"
                  onMouseEnter={() => {
                    setActiveSlide(1)
                  }}
                />
              </>
            )}

            {/* <Flex
              w={activeSlide === 0 ? 'full' : '480px'}
              h="full"
              p="48px"
              direction="column"
              borderRadius="24px"
              bg="brand.blue20"
              position="relative"
              overflow="hidden"
              shadow="primary"
              onMouseEnter={() => {
                setActiveSlide(0)
              }}
            >
              <VStack w="full" h="full" spacing="24px" align="start">
                <Text fontSize="32px" fontFamily="DM Sans Semibold">
                  Pre zákazníkov
                </Text>
                {activeSlide !== 0 && <Spacer />}
                <Text fontSize="16px" maxW="320px" fontFamily="Montserrat">
                  Na každý dopyt <strong>obdržíte</strong> niekoľko{' '}
                  <strong>nezávislých ponúk</strong> od skúsených dodávateľov!
                </Text>
                {activeSlide === 0 && (
                  <>
                    <Spacer />
                    <VStack w="full" align="start" spacing="4px">
                      {CustomerBulletPoints.map((text) => (
                        <HStack w="full" spacing="10px" align="center" key={text}>
                          <IconPoint stroke={1.5} color={Colors.brand.blue100} fill="#BDD4FF" />
                          <Text fontSize="14px" fontFamily="DM Sans Medium" lineHeight="150%">
                            {text}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </>
                )}
              </VStack>
              <HStack pt="24px">
                <Link href={Route.SignUp()}>
                  <Button variant="outline">Registrovať sa</Button>
                </Link>
                {activeSlide === 0 && <Button>Vytvorit dopyt</Button>}
              </HStack>
              {activeSlide === 0 && (
                <Box w="566px" h="570px" right="-100px" bottom="-50px" position="absolute">
                  <Image
                    src="/img/offer-details.png"
                    alt=""
                    fill
                    objectFit="cover"
                    objectPosition="top"
                    style={{ borderRadius: '12px', maxWidth: '680px' }}
                  />
                </Box>
              )}
            </Flex>
            <Flex
              w={activeSlide === 1 ? 'full' : '480px'}
              h="full"
              p="48px"
              direction="column"
              borderRadius="24px"
              bg="#B7F2CA"
              position="relative"
              overflow="hidden"
              shadow="primary"
              onMouseEnter={() => {
                setActiveSlide(1)
              }}
            >
              <VStack w="full" h="full" spacing="24px" align="start">
                <Text fontSize="32px" fontFamily="DM Sans Semibold">
                  Pre dodávateľov
                </Text>
                {activeSlide !== 1 && <Spacer />}
                <Text fontSize="16px" maxW="320px" fontFamily="Montserrat">
                  Spolupracujte s nami a <strong>získajte nové obchodné príležitosti.</strong>
                </Text>
                {activeSlide === 1 && (
                  <>
                    <Spacer />
                    <VStack w="full" maxW="400px" align="start" spacing="4px">
                      {ContractorBulletPoints.map((text) => (
                        <HStack w="full" spacing="10px" align="start" key={text}>
                          <Box w="24px" h="24px">
                            <IconPoint stroke={1.5} color={Colors.brand.blue100} fill="#BDD4FF" />
                          </Box>
                          <Text fontSize="14px" fontFamily="DM Sans Medium" lineHeight="150%">
                            {text}
                          </Text>
                        </HStack>
                      ))}
                    </VStack>
                  </>
                )}
              </VStack>
              <HStack pt="24px">
                <Link href={Route.SignUp()}>
                  <Button variant="outline">Registrovať sa</Button>
                </Link>
                {activeSlide === 1 && <Button>Zistiť viac</Button>}
              </HStack>
              {activeSlide === 1 && (
                <Box w="566px" h="570px" right="-100px" bottom="-50px" position="absolute">
                  <Image
                    src="/img/worker-sm.png"
                    alt=""
                    fill
                    objectFit="cover"
                    objectPosition="top"
                    style={{ borderRadius: '12px', maxWidth: '680px' }}
                  />
                </Box>
              )}
            </Flex> */}
          </Flex>
        </VStack>
      </Container>
    </Box>
  )
}
