import { ReactNode } from 'react'
import { Flex, useBreakpointValue } from '@chakra-ui/react'
import { PropsWithChildrenRequired } from 'constants/typings'
import { Footer } from './Footer/Footer'
import { LandingPageNavbar } from './Navbar/LandingPageNavbar'
import { LandingPageNavbarMobile } from './Navbar/LandingPageNavbarMobile'

const LandingPageLayout = ({ children }: PropsWithChildrenRequired) => {
  const isOnMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
  })

  return (
    <Flex direction="column" position="relative" w="full" minW="100%" minH="100vh" h="fit-content">
      <Flex
        direction="column"
        w="full"
        minH="full"
        bgColor="#fff"
        pb="200px"
        // bg={{ base: 'blue.300', md: 'yellow.200', lg: 'red.400', xl: 'pink.300', '2xl': 'aqua' }}
      >
        {isOnMobile ? <LandingPageNavbarMobile /> : <LandingPageNavbar />}
        {children}
      </Flex>
      <Footer />
    </Flex>
  )
}

export const getLandingPageLayout = (page: ReactNode) => (
  <LandingPageLayout>{page}</LandingPageLayout>
)
export default LandingPageLayout
