import { ReactElement } from 'react'
import { Box, Button, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { IconCircleArrowRight } from '@tabler/icons-react'
import Image from 'next/image'
import Link from 'next/link'
import { Colors } from 'theme/constants'
import { Route } from 'constants/common/routes'

export const UserTypesSectionCard = ({
  title,
  description,
  bulletPoints,
  imgSrc,
  isActive,
  onMouseEnter,
  bgColor,
}: {
  title: string
  description: string | ReactElement
  bulletPoints: string[]
  imgSrc: string
  isActive: boolean
  onMouseEnter: () => void
  bgColor: string
}) => (
  <Flex
    w={isActive ? 'full' : '480px'}
    h="full"
    p="48px"
    direction="column"
    borderRadius="24px"
    bg={bgColor}
    position="relative"
    overflow="hidden"
    shadow="primary"
    onMouseEnter={() => {
      onMouseEnter()
    }}
  >
    <VStack w="full" h="full" spacing="24px" align="start">
      <Text fontSize="32px" fontFamily="DM Sans Semibold">
        {title}
      </Text>
      {!isActive && <Spacer />}
      <Text fontSize="16px" maxW="320px" fontFamily="Montserrat">
        {description}
      </Text>
      {isActive && (
        <>
          <Spacer />
          <VStack w="full" maxW="460px" align="start" spacing="4px">
            {bulletPoints.map((text) => (
              <HStack w="full" spacing="10px" align="center" key={text}>
                <Box w="24px" h="24px">
                  <IconCircleArrowRight stroke={1.5} color={Colors.brand.blue100} fill="#BDD4FF" />
                </Box>
                <Text fontSize="14px" fontFamily="DM Sans Medium" lineHeight="150%">
                  {text}
                </Text>
              </HStack>
            ))}
          </VStack>
        </>
      )}
    </VStack>
    <HStack pt="24px">
      <Link href={Route.SignUp()}>
        <Button variant="outline">Registrovať sa</Button>
      </Link>
      {isActive && (
        <Link href={Route.Public.CreateDemand()}>
          <Button>Vytvoriť dopyt</Button>
        </Link>
      )}
    </HStack>
    {isActive && (
      <Box w="566px" h="570px" right="-100px" bottom="-50px" position="absolute">
        <Image
          src={imgSrc}
          alt=""
          fill
          objectFit="cover"
          objectPosition="top"
          style={{ borderRadius: '12px', maxWidth: '680px' }}
        />
      </Box>
    )}
  </Flex>
)
