import {
  add,
  differenceInDays,
  differenceInMonths,
  format,
  formatDuration,
  intervalToDuration,
} from 'date-fns'
import { sk } from 'date-fns/locale'

export const getFormattedDate = (date: string) => format(new Date(date), 'dd.MM. yyyy')

export const getFormattedDateWithTime = (date: string) => format(new Date(date), 'dd/MM/yyyy HH:mm')

export const getDifferenceInMonths = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => differenceInMonths(new Date(endDate), new Date(startDate)).toLocaleString()

export const getListingLength = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const { years, months, days } = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  })
  return formatDuration({
    ...(years !== 0 && { years }),
    ...(months !== 0 && { months }),
    ...(days !== 0 && years === 0 && { days }),
  })
}

const getFormattedTimeDuration = (startDate: Date, endDate: Date) => {
  const durationInFullDays = differenceInDays(endDate, startDate)
  const { years, months, days, hours } = intervalToDuration({
    start: startDate,
    end: endDate,
  })
  if (durationInFullDays === 0 && hours === 0) {
    return '< 1 hour'
  }
  return formatDuration({
    ...(years && years >= 1 && { years }),
    ...(months && months >= 1 && { months }),
    ...(durationInFullDays >= 1 && { days }),
    ...(durationInFullDays < 5 && { hours }),
  })
}

export const getTimeLeftFromNow = ({ endDate }: { endDate: string }) => {
  const startDateObject = new Date()
  const endDateObject = new Date(endDate)
  return getFormattedTimeDuration(startDateObject, endDateObject)
}

export const getDateDifferenceMessage = ({
  interval,
  label,
  isInclusiveEndDay = false,
}: {
  interval: [Date, Date] | undefined
  label?: string
  isInclusiveEndDay?: boolean
}): string => {
  if (!interval) {
    return ''
  }
  const [start, end] = interval
  if (!start || !end) {
    return ''
  }
  const duration = intervalToDuration({
    start: new Date(interval[0]),
    end: isInclusiveEndDay ? add(new Date(interval[1]), { seconds: 1 }) : new Date(interval[1]),
  })
  const differenceMessage = formatDuration(duration, { delimiter: ', ' })
  return `${differenceMessage}${label ? ` ${label}` : ''}`
}

export const getTimeElapsedFromClosure = ({ tenderEndsAtUtc }: { tenderEndsAtUtc: string }) => {
  const startDateObject = new Date(tenderEndsAtUtc)
  const endDateObject = new Date()
  return getFormattedTimeDuration(startDateObject, endDateObject)
}

export const getTimePassedFromGivenDate = (publishedDate: string) => {
  const { years, months, days, hours, minutes } = intervalToDuration({
    start: new Date(publishedDate),
    end: new Date(),
  })

  return formatDuration(
    {
      ...(years !== undefined && { years }),
      ...(months !== undefined && { months }),
      ...(days !== undefined && months === undefined && years === undefined && { days }),
      ...(hours !== undefined &&
        days === undefined &&
        months === undefined &&
        years === undefined && { hours }),
      ...(minutes !== undefined &&
        hours === undefined &&
        days === undefined &&
        months === undefined &&
        years === undefined && { minutes }),
    },
    { locale: sk },
  )
}
